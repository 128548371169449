<template>
  <div class="help-detail">
    <helloWorld />
    <div class="helpTop">
      <h3>
        <span></span>
         数字藏品铸造守则
      </h3>
      <div class="helpMain">
        <div class="content">
          感谢选择融汇科技服务，为维护您自身权益，在正式使用融汇科技数字藏品服务前，建议您仔细阅读各条款具体表述。
          <br />
          当您按照申请页面提示填写信息、勾选“我同意以上条款”勾选框或点击“我已阅读并同意以上协议”且提交申请后，即表示您已经充分阅读、理解并接受本协议的全部内容，并与融汇科技达成一致。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止使用融汇科技数字藏品服务。
          <br />
          当您在融汇科技上传数字藏品即可视为您愿意转让数字藏品的物权、发表权、使用权、修改权、复制权、发行权、出租权、展览权、表演权、放映权、信息网络传播权、摄制权、改编权，汇编权和其他应当由著作权人享有的权利，买家购买后即视为受让上述权利。
          <br />
          <span style="font-weight:bold">1. 亚联融汇数字藏品服务</span>
          <br />
          <span style="font-weight:bold">1.1 概述</span>
          <br />
          融汇科技为提供一个通过区块链记录数字商品/作品所有权的平台。您可以通过融汇科技数字藏品服务为您的商品/作品铸造专属的数字藏品/数字资产（“数字藏品铸造”），亦可在融汇科技上交易您通过融汇科技数字藏品服务铸造的数字藏品（“数字藏品交易”）。
          <br />
          <span style="font-weight:bold">1.2 数字藏品铸造</span>
          <br />
          1.2.1 您上传至融汇科技的商品/作品，可以通过融汇科技数字藏品服务为该作品铸造专属的数字藏品，一旦铸造成功，即生成唯一的、不可替代的数字藏品数字资产。
          <br />
          1.2.2 用户知悉并同意，在开放的区块链分类账上铸造数字藏品需要支付一定费用，不同区块链分类账上的铸造费用标准不同，同一区块链分类账上的铸造费用标准在不同时段可能不同。在铸造数字藏品时，您可以选择不同铸造费用标准的区块链分类账铸造您的数字藏品，具体铸造费用以融汇科技公布的实时金额为准。融汇科技保留随时更新、调整、修改数字藏品铸造费用标准的权利。鉴于数字藏品铸造服务数字化和即时性的特性，您支付的铸造费用在任何情况下均不能退款。
          <br />
          <span style="font-weight:bold">1.3 数字藏品交易</span>
          <br />
          您的数字藏品商品/作品对融汇科技所有用户可见，您亦可在融汇科技浏览他人发布的数字藏品商品/作品。融汇科技为用户提供数字藏品交易服务。您理解并同意，融汇科技为您提供数字藏品交易服务付出了大量成本，数字藏品交易服务并非为免费服务，使用数字藏品交易服务将收取一定费用。具体地，当您使用融汇科技数字藏品交易服务并成功售出您的数字藏品时，融汇科技将向您收取该数字藏品交易金额一定比例的服务费用。
          <br />
          <span style="font-weight:bold">2. 账号和私钥</span>
          <br />
          2.1 为了使用融汇科技数字藏品服务，用户需要注册一个融汇科技账号，并进行实名认证以遵守国家法律法规的相关要求。
          <br />
          2.2 用户在申请使用融汇科技数字藏品服务时，融汇科技会为您自动生成专属的数字藏品服务私钥。如果私钥遗失，您将无法找回您的加密资产。除与用户有特别的约定外，融汇科技不负责为用户存储或备份私钥信息。因此，融汇科技不对用户私钥遗失造成的损失承担任何责任。用户须采取必要的手段来保障私钥不被他人获取。
          <br />
          <span style="font-weight:bold">3. 用户上传的商品/作品</span>
          <br />
          3.1 融汇科技依法保护知识产权。您承诺并保证上传到融汇科技的数字藏品商品/作品拥有完整的知识产权或授权，若为数字艺术品，则该数字藏品作品应当未转让给他人、未以排他/独占方式许可给他人，也未委托他人独家代理其许可。您须保证您上传至融汇科技的数字藏品商品/作品无任何权利瑕疵。
          <br />
          3.2 融汇科技禁止用户为含有以下内容的商品/作品铸造和/或交易数字藏品：
          <br />
          ● 违反国家法律、行政法规、部门规章或其他规范性文件要求的；
          <br />
          ● 宣扬恐怖主义、极端主义、封建迷信、民族仇恨，歧视他人或教唆犯罪的；
          <br />
          ● 传播低俗、淫秽、色情、赌博、暴力或其他类型不恰当内容的；
          <br />
          ● 侮辱或诽谤他人、毁坏他人名誉、泄露他人隐私或个人信息的；
          <br />
          ● 编造或散布谣言、发布虚假信息、扰乱社会秩序的；
          <br />
          ● 不是用户本人原创或有权许可他人使用的；
          <br />
          ● 侵犯他人肖像权、著作权、商标权、外观专利权、商业秘密或其他权益的；
          <br />
          ● 含有病毒或恶意代码，意图中断、破坏或限制网络计算机系统的内容的；
          <br />
          ● 未经允许发布广告、促销信息、垃圾信息或进行传销式推广的。
          <br />
          3.3 用户上传的数字藏品作品上所涉及的肖像权和其他第三方权利（如商标权、外观设计专利权、字体著作权等），由用户自行核实、取得或规避相关的权利，融汇科技不承担该类权利的核实、取得或规避的责任，也不承担因用户使用未取得上述权利的原创作品而产生的侵权责任。用户上传的数字藏品作品涉及肖像权和其他第三方权利，引起的与第三方的纠纷、诉讼，由用户直接向第三方独立承担责任；因此给融汇科技带来损失的，融汇科技保留向用户追偿的权利。
          <br />
          <span style="font-weight:bold">4. 使用数字藏品服务的特别约定</span>
          <br />
          4.1 不可抗力：本协议所称的不可抗力包括但不限于国家政策、政府行为、网络原因、自然灾害、战争、动乱、罢工、黑客袭击或任何其他不能预见、不能避免、不能克服的客观情况。出现不可抗力时，我们将采取合理行动积极促使融汇科技数字藏品服务尽快恢复正常。因不可抗力造成融汇科技数字藏品服务暂停、中止或终止，给用户带来损失的，用户同意免除融汇科技（北京）科技有限公司的责任。
          <br />
          4.2 用户使用融汇科技数字藏品服务，须遵守国家最新法律法规的要求，不侵害他人合法权益、不违背社会公序良俗。
          <br />
          4.3 融汇科技数字藏品服务为我们运营的融汇科技服务的一部分，用户理解并同意，除遵守本协议外，用户使用融汇科技数字藏品服务须遵守《融汇科技服务协议》等协议（前述协议统称为“融汇科技用户协议及规则”）。本协议有约定的，适用本协议的约定；本协议没有约定的，适用亚联融汇用户协议及规则中其他协议/规则的约定。
          <br />
          <span style="font-weight:bold">5. 法律适用及争议解决</span>
          <br />
          5.1 本协议一切事宜适用中华人民共和国法律并依其解释。
          <br />
          5.2 双方就本协议的签订、解释、履行等问题发生争议，应首先友好协商解决，协商不成的，双方均同意向亚联融汇（北京）科技有限公司所在地的人民法院起诉。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.help-detail .helpTop {
    padding: 50px 0px 0;
    max-width: 840px;
    margin: 0 auto;
}
.help-detail .helpTop h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 50px;
}
.help-detail .helpTop h3 span {
    width: 4px;
    height: 24px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.help-detail .helpMain {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
}
.help-detail .helpMain .content {
    font-size: 14px;
    font-weight: 400;
    color: #1d202b;
    line-height: 30px;
    min-height: 1150px;
}
</style>